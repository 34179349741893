$composition-color-black: rgb(20, 20, 20);
$composition-color-red: rgb(217, 19, 42);
$composition-color-white: rgb(246, 250, 250);
$composition-color-yellow: rgb(251, 209, 85);
$composition-color-blue: rgb(11, 47, 180);
$composition-color-gray: rgb(60, 60, 60);
$bold-border: 18px solid $composition-color-black;

.app {
  display: flex;
  justify-content: center;
  //align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  main {
    width: 100%;
    background: antiquewhite;
  }
}

body {
  background: $composition-color-black;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  display: flex;
  flex-direction: row;
  border-bottom: $bold-border;

  > h1 {
    background: $composition-color-red;
    margin: 0;
    padding: 0;

    a {
      display: inline-block;
      padding: 20px 40px;

      &:link,
      &:visited {
        color: $composition-color-black;
        text-decoration: none;
      }

      &:hover {
        color: $composition-color-white;
      }
    }
  }

  > div:last-child {
    border-left: $bold-border;
    content: " ";
    background: $composition-color-yellow;
    flex-grow: 1;
  }
}

.stages {
  --operation-stage-width: 300px;

  display: flex;
  width: 100%;
  flex-direction: row;

  > * {
    flex-grow: 1;
    border-right: $bold-border;

    &:last-child {
      border-right: 0;
    }
  }

  .import-stage {
    background: $composition-color-white;
    width: calc((100% - var(--operation-stage-width)) / 2);

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $composition-color-white;
      margin: 0;
      padding: 40px;
      border-bottom: $bold-border;
    }

    .form {
      display: flex;
      color: $composition-color-white;
      background: $composition-color-gray;
      border-bottom: $bold-border;

      > input {
        display: block;
        flex-grow: 1;
        margin: 5px;
        padding: 20px;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .operation-stage {
    background: $composition-color-blue;
    color: $composition-color-white;
    width: var(--operation-stage-width);

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 60px 0;
      color: $composition-color-black;
      border-bottom: $bold-border;
      background: $composition-color-white;
    }

    .operation-fields {
      padding: 20px;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px;
        width: 100%;
        font-size: 16px;

        > label {
          margin-right: 10px;
          flex-grow: 1;
        }

        > input[type="number"] {
          text-align: right;
          width: 52px;
          font-size: 16px;
        }
      }
    }
  }

  .export-stage {
    background: $composition-color-white;
    width: calc((100% - var(--operation-stage-width)) / 2);

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $composition-color-red;
      margin: 0;
      padding: 20px;
      border-bottom: $bold-border;
    }
  }

  .import-stage,
  .export-stage {
    .previews {
      > div {
        width: 100%;
        border-bottom: $bold-border;
        background: url('images/transparent.png');

        > img {
          display: block;
          width: 100%;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

footer {
  display: flex;
  flex-direction: row;
  border-top: $bold-border;

  > * {
    border-right: $bold-border;
    background: $composition-color-white;

    &:last-child {
      border-right: 0;
    }
  }

  > .info {
    background: $composition-color-yellow;
    display: flex;
    justify-content: center;
    align-items: center;

    > a {
      display: block;

      &:link,
      &:visited {
        color: $composition-color-black;
        text-decoration: none;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  > address {
    flex-grow: 2;

    a {
      //display: flex;
      justify-content: center;
      align-items: center;
      display: block;
      padding: 20px;
      text-align: center;

      &:link,
      &:visited {
        color: $composition-color-black;
        font-style: normal;
        text-decoration: none;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  > div {
    flex-grow: 2;
    content: " ";
    background: $composition-color-white;
  }

  > div:last-child {
    flex-grow: 6;
    content: " ";
    background: $composition-color-white;
  }
}
