.color-pick-button {
  $height: 24px;

  background: url('images/transparent.png');
  height: $height;

  > button {
    width: 38px;
    height: $height;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
  }
}

.sketch-picker {
  position: absolute;
}
